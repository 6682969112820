import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import { IoIosLock } from 'react-icons/io';

import { AppContext } from '../App';

export default function SigninPage() {
	const { SERVER_URL, setLoadingPrompt, setOpenLoading, setUser } =
		useContext(AppContext);

	const handleSubmit = async (e) => {
		e.preventDefault();

		const name = e.target[0].value;
		const password = e.target[1].value;
		if (name === '') {
			toast.warn('Please input user name');
			return;
		}

		if (password === '') {
			toast.warn('Please input password');
			return;
		}

		setLoadingPrompt('Logging in...');
		setOpenLoading(true);
		try {
			const { data } = await axios.post(
				`${SERVER_URL}/api/v1/user/login`,
				{
					name,
					password,
				},
				{
					headers: {
						'Content-Type': 'application/json',
					},
				}
			);
			if (data.success) {
				localStorage.setItem('access-token', data.accessToken);
				setUser(data.user);
			} else {
				toast.warn('Failed to login');
			}
		} catch (err) {
			console.log(err);
			setOpenLoading(false);
			toast.warn('Failed to login');
		}
	};

	return (
		<div className='flex items-center justify-center min-h-screen font-sans bg-gradient-to-r from-black to-white pb-7'>
			<div className='relative max-sm:w-[450px] m-6 max-w-[500px] w-full bg-white p-8 rounded-lg shadow-lg animate-fadeIn'>
				{/* <IoIosLock className='text-[50px] text-red-normal mb-3 mx-auto' /> */}
				<h2 className='mb-1 text-3xl font-bold text-center text-gray-800 uppercase'>
					Login
				</h2>
				<p className='mb-5 text-sm text-center text-gray-600'>
					Enter your name and password
				</p>
				<form className='space-y-5' onSubmit={handleSubmit}>
					<div>
						<div className='font-sans text-xs text-gray-600 uppercase'>
							Name<span className='pl-1 text-red-normal'>*</span>
						</div>
						<input
							id='name'
							className='w-full px-3 py-2 mt-1 font-sans text-sm text-gray-800 placeholder-gray-400 transition border border-gray-300 rounded outline-none focus:ring-2 focus:ring-purple-500'
							placeholder='Enter your name'
						/>
					</div>
					<div>
						<div className='font-sans text-xs text-gray-600 uppercase'>
							Password<span className='pl-1 text-red-normal'>*</span>
						</div>
						<input
							id='password'
							type='password'
							className='w-full px-3 py-2 mt-1 font-sans text-sm text-gray-800 placeholder-gray-400 transition border border-gray-300 rounded outline-none focus:ring-2 focus:ring-purple-500'
							placeholder='Enter your password'
						/>
					</div>
					<div className='flex justify-center w-full gap-2'>
						<button
							type='submit'
							className='w-full px-6 py-2 font-sans text-xs font-medium text-center text-white uppercase transition duration-150 ease-in-out transform rounded bg-red-normal hover:bg-red-600 active:scale-95 focus:outline-none'>
							Login
						</button>
					</div>
				</form>
				<div className='flex items-center justify-between mt-3 text-sm text-gray-600'>
					<p>
						Don't have an account?&nbsp;
						<Link to='/register' className='text-red-normal hover:underline'>
							Register
						</Link>
					</p>
					<p>
						Go to&nbsp;
						<Link to='/' className='text-red-normal hover:underline'>
							Home
						</Link>
					</p>
				</div>
			</div>
		</div>
	);
}
