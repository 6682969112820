import Modal from '../Base/Modal';

export default function LUTDialog({ isOpen, onOK, onCancel }) {
	const handleOK = () => {
		onOK(true);
	};

	const handleCancel = () => {
		onCancel(false);
	};

	return (
		<Modal isOpen={isOpen} onClose={handleCancel}>
			<div className='flex flex-col pt-5 w-[440px] font-sans'>
				<div className='items-center w-full h-auto px-5 py-5 md:py-0 bg-gray-dark rounded-b-md'>
					<div className='mt-5'>
						<div className='font-sans text-xs my-7 text-gray-normal'>
							To use more than 4 wallets to buy first, you need to use the Lookup Table.
							Do you want to create Lookup Table?
							{/* <span className='pl-1 text-red-normal'>*</span> */}
						</div>
					</div>
					<div className='flex items-center justify-center gap-5 my-5'>
						<button
							className='pl-3 pr-4 h-button grow rounded-[4px] justify-center items-center gap-1 inline-flex bg-red-normal active:scale-95 transition duration-90 ease-in-out transform focus:outline-none text-xs font-medium text-center text-white uppercase disabled:text-gray-border disabled:opacity-50 disabled:cursor-not-allowed whitespace-nowrap'
							onClick={handleOK}>
							Yes
						</button>
						<button
							className='pl-3 pr-4 h-button grow rounded-[4px] justify-center items-center gap-1 inline-flex bg-[#262626] active:scale-95 transition duration-90 ease-in-out transform focus:outline-none text-xs font-medium text-center text-white uppercase disabled:text-gray-border disabled:opacity-50 disabled:cursor-not-allowed whitespace-nowrap'
							onClick={handleCancel}>
							No
						</button>
					</div>
				</div>
			</div>
		</Modal>
	);
}
