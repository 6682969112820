import React from 'react';
import Accordion from './Accordian';

const FaqComponent = () => {
	const faqElt = [
		{
			category: 'Frequently Asked Questions',
			faqs: [
				{
					id: 1,
					text: 'How do I sign up for LaunchpadX?',
					description: [
						'To sign up for Pump.fun, visit our website and click on the "Register" button. Follow the prompts to create your account by providing your name and setting a secure password. Once you verify your name, you can log in and start using the platform.',
					],
				},
				{
					id: 2,
					text: 'How do I launch a token on LaunchpadX?',
					description: [
						'Launching a token on LaunchpadX is straightforward. After logging in, go to the "Dashboard page" section, create the New Porject and fill in the necessary details about your token (such as name, symbol, and so on), and follow the step-by-step instructions to deploy your token.',
					],
				},
				{
					id: 3,
					text: 'What are the requirements for launching a token on LaunchpadX?',
					description: [
						'To launch a token on LaunchpadX, you need to have a verified account, sufficient funds to cover gas fees for the blockchain you choose, and detailed information about your token, such as its name, symbol, and logo image.',
					],
				},
				{
					id: 4,
					text: 'Can I track the performance of my token after launch?',
					description: [
						'Yes, LaunchpadX offers a comprehensive analytics dashboard that allows you to track the performance of your token in real-time. You can monitor key metrics such as trading volume, holder distribution, and price changes, helping you make informed decisions and adjustments to your strategy.',
					],
				},
				{
					id: 5,
					text: 'What support is available if I encounter issues?',
					description: [
						'If you encounter any issues while using LaunchpadX, our support team is here to help. ',
					],
				},
			],
		},
	];

	return (
		<section id='FAQ' className='flex justify-center w-full py-12'>
			<div
				className='w-[90%] custom-md:w-[80%] flex flex-col gap-y-4 md:gap-y-8'
				data-aos='zoom-in-down'>
				<p className='flex justify-center text-2xl font-bold text-center text-white md:text-4xl md:block'>
					Frequently Asked Questions
				</p>
				<p className='text-sm font-semibold text-center text-white md:text-lg'>
					Find answers to your questions here!
				</p>
				<div className='flex flex-col custom-md:flex-row'>
					<div className='custom-md:grow'>
						{faqElt[0].faqs.map((faq, index) => {
							return <Accordion key={index} {...faq} />;
						})}
					</div>
				</div>
			</div>
		</section>
	);
};

export default FaqComponent;
