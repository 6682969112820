import React from 'react';
import { BsTwitter } from 'react-icons/bs';
import { FaTelegram, FaDiscord } from 'react-icons/fa';

const FooterComponent = () => {
	return (
		<>
			<section className='w-full'>
				<hr className='w-full h-[1px] bg-white' />
				<div className='w-[90%] custom-md:w-[80%] mx-auto flex flex-col mt-4 gap-y-6 md:gap-y-8'>
					<div className='flex flex-col items-center w-full gap-y-8 md:gap-y-0'>
						<div className='flex justify-center'>
							<img
								src='/logo.png'
								className='h-[150px]  object-cover'
								alt='Logo'
							/>
						</div>
						<div className='flex items-center justify-center w-full mt-4 text-white md:w-auto gap-x-6 md:gap-x-12'>
							{/* <a href='https://t.me/powerful115'>
								<FaTelegram size={28} />
							</a> */}
							{/* <a href='https://twitter.com/powerful115 '>
								<BsTwitter size={25} />
							</a> */}
							{/* <a href='https://discord.gg/powerful115 '>
								<FaDiscord size={32} />
							</a> */}
						</div>
						<div className='flex-1' />
					</div>
					{/* <p className='text-xs text-center text-white md:text-lg'>
            <a href='mailto:info@launchpad.io' className='text-white hover:text-indigoBlue'>
              info@launchpad.io
            </a>{' '}
            |{' '}
            <a
              href='https://aisinger.io/wp-content/uploads/2024/04/Terms-Of-Service.pdf'
              className='text-white hover:text-indigoBlue'>
              Terms & Conditions
            </a>{' '}
            |{' '}
            <a
              href='https://aisinger.io/wp-content/uploads/2024/04/Privacy-Policy.pdf'
              className='text-white hover:text-indigoBlue'>
              Privacy Policy
            </a>
          </p> */}
					<p className='text-xs text-center text-white md:text-lg'>
						© {new Date().getFullYear()} LaunchpadX Platform. All rights
						reserved
					</p>
					<hr className='w-full h-[1px] bg-white' />
					{/* <p className='text-center px-[2.5%] md:px-[1%] text-xs md:text-lg text-white'>
            Cryptocurrencies and digital assets may be unregulated in your jurisdiction. The value of cryptocurrencies and digital assets may go down as well as up. Profits may be subject to capital gains or other taxes applicable in your jurisdiction.
          </p> */}
				</div>
			</section>
		</>
	);
};

export default FooterComponent;
