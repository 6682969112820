import { useState } from 'react';
import Modal from '../Base/Modal';

export default function TokenAmountDialog({
	isOpen,
	onOK,
	onCancel,
	isPumpfun = false,
}) {
	const [devAmount, setDevAmount] = useState('');
	const [minAmount, setMinAmount] = useState('');
	const [maxAmount, setMaxAmount] = useState('');
	const [buyAllToken, setBuyAllToken] = useState(false);

	const handleBuyAllToken = (e) => {
		const newWalletAllChecked = !buyAllToken;
		setBuyAllToken(newWalletAllChecked);
	};

	const handleOK = () => {
		if (devAmount !== '' && minAmount !== '' && maxAmount !== '') {
			onOK(devAmount, minAmount, maxAmount, buyAllToken);
		}
	};

	const handleCancel = () => {
		setDevAmount('');
		setMinAmount('');
		setMaxAmount('');
		onCancel();
	};

	return (
		<Modal isOpen={isOpen} onClose={handleCancel}>
			<div className='flex flex-col pt-5 w-[440px] font-sans'>
				<div className='flex items-center justify-start w-full h-auto px-5 py-3 rounded-t-md bg-gray-highlight'>
					<div className='font-sans text-sm font-medium text-white uppercase'>
						Set Token Amount
					</div>
				</div>
				<div className='items-center w-full h-auto px-5 py-5 md:py-0 bg-gray-dark rounded-b-md'>
					<div className='mt-5'>
						{isPumpfun === true ? (
							<div className='flex items-center font-sans text-xs text-center uppercase text-gray-normal'>
								<input
									type='checkbox'
									id='butTotakCheck'
									className='w-4 h-4 outline-none bg-gray-highlight opacity-20 accent-green-normal ring-0'
									checked={buyAllToken}
									onChange={handleBuyAllToken}
								/>
								<label
									className='ml-4 font-sans text-xs uppercase text-gray-normal'
									htmlFor='butTotakCheck'>
									Buy all tokens
								</label>
							</div>
						) : (
							<></>
						)}
						<div className='mt-4 font-sans text-xs uppercase text-gray-normal'>
							Dev Amount<span className='pl-1 text-red-normal'>*</span>
						</div>
						<input
							className='outline-none border border-gray-border font-sans text-white placeholder:text-gray-border text-sm px-2.5 bg-transparent w-full h-button mt-1'
							placeholder='Enter dev token amount'
							value={devAmount}
							onChange={(e) => setDevAmount(e.target.value)}
						/>
					</div>
					<div className='mt-4'>
						<div className='mt-4 font-sans text-xs uppercase text-gray-normal'>
							Min Amount<span className='pl-1 text-red-normal'>*</span>
						</div>
						<input
							className='outline-none border border-gray-border font-sans text-white placeholder:text-gray-border text-sm px-2.5 bg-transparent w-full h-button mt-1'
							placeholder='Enter minimum token amount'
							value={minAmount}
							onChange={(e) => setMinAmount(e.target.value)}
						/>
					</div>
					<div className='mt-4'>
						<div className='font-sans text-xs uppercase text-gray-normal'>
							Max Amount<span className='pl-1 text-red-normal'>*</span>
						</div>
						<input
							className='outline-none border border-gray-border font-sans text-white placeholder:text-gray-border text-sm px-2.5 bg-transparent w-full h-button mt-1'
							placeholder='Enter maximum token amount'
							value={maxAmount}
							onChange={(e) => setMaxAmount(e.target.value)}
						/>
					</div>
					<div className='flex items-center justify-center gap-5 my-5'>
						<button
							className='pl-3 pr-4 h-button grow rounded-[4px] justify-center items-center gap-1 inline-flex bg-red-normal active:scale-95 transition duration-90 ease-in-out transform focus:outline-none text-xs font-medium text-center text-white uppercase disabled:text-gray-border disabled:opacity-50 disabled:cursor-not-allowed whitespace-nowrap'
							onClick={handleOK}>
							OK
						</button>
						<button
							className='pl-3 pr-4 h-button grow rounded-[4px] justify-center items-center gap-1 inline-flex bg-[#262626] active:scale-95 transition duration-90 ease-in-out transform focus:outline-none text-xs font-medium text-center text-white uppercase disabled:text-gray-border disabled:opacity-50 disabled:cursor-not-allowed whitespace-nowrap'
							onClick={handleCancel}>
							Cancel
						</button>
					</div>
				</div>
			</div>
		</Modal>
	);
}
