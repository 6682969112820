import React, { useState } from 'react';
import { FaPlus, FaMinus } from 'react-icons/fa6';

const Accordion = (prop) => {
	const [showDropdown, setShowDropDown] = useState(false);

	const handleShowContent = () => {
		setShowDropDown((prev) => !prev);
	};

	return (
		<div className='flex flex-col w-full mb-4 '>
			<div
				className={`hover:text-mainGreen hover:cursor-pointer group group-hover:cursor-pointer p-4 border-[0.01px] border-x-[0.1px] border-[#ebebeb] ${
					showDropdown ? 'text-white' : 'text-white'
				} `}
				onClick={handleShowContent}>
				<div className='flex justify-between w-full'>
					<div className='flex gap-x-4'>
						<p className='font-medium md:font-semibold'>{prop.text}</p>
					</div>
					{showDropdown ? (
						<FaMinus className='text-white' />
					) : (
						<FaPlus className='text-white' />
					)}
				</div>
			</div>
			{showDropdown && (
				<div className='flex flex-col gap-y-6 text-left text-white border-[0.01px] border-[#ebebeb] p-4'>
					{prop.description.map((desc, index) => {
						return <p key={index}>{desc}</p>;
					})}
				</div>
			)}
		</div>
	);
};

export default Accordion;
